<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div v-if="!loading">

            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button
                        @click="$root.$children[0].openModal('customer-type-modal', {id: 0}, afterSave, {width: '800px'})"
                        variant="primary"
                        class="mb-2"
                    >
                        <font-awesome-icon icon="plus"/>
                        {{ $t('common.button.create') }}
                    </b-button>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">

                    <b-table-simple striped>
                        <b-thead head-variant="info">
                            <b-th>{{ $t('settings.customer.label.name') }}</b-th>
                            <b-th>{{ $t('settings.customer.label.actions') }}</b-th>
                        </b-thead>

                            <b-tr v-for="type in types" :key="type.id">
                                <b-td>{{ type.name }}</b-td>
                                <b-td>
                                    <b-button
                                        @click="$root.$children[0].openModal('customer-type-modal', {id: type.id},afterSave,{width: '800px'})"
                                        size="sm"
                                        variant="primary"
                                        class="mr-2"
                                        :title="$t('common.title.edit')"
                                    >
                                        <font-awesome-icon icon="edit"/>
                                    </b-button>

                                    <b-button @click="deleteType(type.id)"
                                              size="sm"
                                              :disabled="type.is_base==1"
                                              variant="danger"
                                              :title="$t('common.title.delete')"
                                    >
                                        <font-awesome-icon icon="trash"/>
                                    </b-button>
                                </b-td>
                            </b-tr>
                    </b-table-simple>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import Draggable from "vuedraggable"

export default {
    name: 'Settings-customer-type',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            types: [],
        }
    },
    methods: {
        ...mapGetters('Customer', ['getTypes']),
        shown() {
            const typesFetch = this.$store.dispatch('Customer/fetchTypes')

            Promise.all([typesFetch])
                .then(() => {
                    this.types = this.getTypes()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        afterSave() {
            this.$store.dispatch('CommonData/fetchCommonData')
            this.shown()
            this.$root.afterSettingsUpdate()
        },
        deleteType(id) {
            this.$removeConfirm('Customer/deleteType', {id}, this.shown)
        }
    },
    mounted() {
        this.shown()
    }
}
</script>